import React from "react";
import { employees } from "./AboutUsData";

const OurTeam = () => {
  // const [currentEmployee, setCurrentEmployee] = useState(null);

  // const getCurrentEmployee = (Id) => {
  //   employees.forEach((emp) => {
  //     if (emp.id === Id) {
  //       setCurrentEmployee(emp);
  //     }
  //   });
  // };

  return (
    <section className="container our-team py-5 ">
      <h2 className="fw-bold primary-color-heading text-center-smallScreen reveal fade-up">
        Our Team
      </h2>
      <div className="row">
        {employees.map((person) => {
          return (
            <div
              className="col-custom-5 col-md-4 col-6 reveal fade-up"
              key={person.id}
            >
              <div className="card shadow-sm mt-4">
                <div className="img-holder">
                  <img
                    src={person.photo}
                    alt="..."
                    className="card-img-top employee-photo"
                  />
                </div>
                <div className="card-body">
                  <h5 className="card-text primary-color-heading text-capitalize">
                    {person.name}
                  </h5>
                  <p
                    className=" designationPara card-text fw-bold"
                    style={{ color: "var(--danger)" }}
                  >
                    {person.designation}
                  </p>
                </div>
              </div>
            </div>
          );
        })}
      </div>
      {/* Modal */}
      {/* <div
        class="modal fade"
        id="currentEmployeeModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-xl modal-dialog-centered emp-modal">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">
                Employee Details
              </h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              {currentEmployee ? (
                <>
                  <div className="container-fluid">
                    <div className="row">
                      <div className="col-lg-4 col-md-4 text-center text-md-start">
                        <img
                          src={currentEmployee.photo}
                          className="img-fluid"
                          alt="..."
                        />
                      </div>
                      <div className="col-lg-8 col-md-8 text-center text-md-start">
                        <h1 className="mt-3 mt-md-0">{currentEmployee.name}</h1>
                        <hr />
                        <div className="row">
                          <div className="col-md-4 col-lg-3 col-xl-2 fw-bold">
                            Designation:
                          </div>
                          <div className="col-md-8 col-lg-9 col-xl-10">
                            {currentEmployee.designation}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <>Details Not Available</>
              )}
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div> */}
    </section>
  );
};

export default OurTeam;
