import React from "react";
import { NavLink } from "react-router-dom";

const PageNotFoundError = () => {
  return (
    <div className="container vh-100 page-not-found">
      <h1 className="primary-color-heading text-center">
        This Route Is Not Available
      </h1>
      <div className="text-center mt-5">
        <NavLink to="/">
          <button className="btn btn-lg primary-btn">Home</button>
        </NavLink>
      </div>
    </div>
  );
};

export default PageNotFoundError;
