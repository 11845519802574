import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";

const Careers = () => {
  useEffect(() => {
    document.getElementById("main-title").textContent = "uvXcel - Careers";
  });

  return (
    <>
      <div className="career-top-container">
        <div className="overlay"></div>

        <section className="container-fluid career-section-top ">
          <div className="careers padding-and-height-for-main-container">
            <div className="container active fade-up">
              <h1 className="careerPage-heading fw-bold text-center mb-4">
                Career Opportunities
              </h1>

              {/* Job application form link */}
              <div className="container">
                <div className="row justify-content-center">
                  <div className="col-lg-10 ">
                    <p className="fw-bold mt-5 text-white form-link-text">
                      Interested candidates to send their profile to
                      hr@uvxcel.com OR use the Link below to fill the Candidate
                      Information Form.
                      <br />
                      <NavLink className="candidate-form-link" to="/applyform">
                        Candidate Form
                      </NavLink>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default Careers;
