// Icons for current engagement section
import { AiFillDatabase } from "react-icons/ai";
import { FaHandshake } from "react-icons/fa";
import { RiFileExcel2Fill } from "react-icons/ri";
import OurValues from "../images/company value vision mission/values.avif";
import OurVision from "../images/company value vision mission/vision.avif";
import OurMission from "../images/company value vision mission/mission.avif";
import CG from "../images/Team_member/Chanchalkumar Ghevade.png";
import MK from "../images/Team_member/Monika korde.png";
import SS from "../images/Team_member/Sara Sheikh.png"; 
import SY from "../images/Team_member/Sachin Yadav.png";
import SHP from "../images/Team_member/Shubham Parab.png";
import ST from "../images/Team_member/Sagar Tilekar.png";
import GT from "../images/Team_member/Gayatri Tajane.png";
import PG from "../images/Team_member/Prapti Gomekar.png";
import RK from "../images/Team_member/Rasheed Kannan.png";
import RY from "../images/Team_member/Ranjit Yadav.png";
import VR from "../images/Team_member/Vaibhav Rajgure.png";
import JB from "../images/Team_member/Jitendra Badgujar.png";
import SUP from "../images/Team_member/Supriya Pawar.jpg";

// Current engagement data
export const engagementData = [
  {
    id: "eng001",
    title: "CUSTOMER OUTREACH",
    icon: <FaHandshake size={45} />,
    desc: "Tracking webpages consumption and user behavior to improve the website for better outreach with new and existing customers. Provided managed services to deliver adhoc database development.",
  },
  {
    id: "eng002",
    title: "DATA SERVICES",
    icon: <AiFillDatabase size={45} />,
    desc: "Built team to perform ongoing standardized framework and configuration based ETL development using SQL Server Integration Services for 35+ clients to track telecom data into a centralized data repository.",
  },
  {
    id: "eng003",
    title: "BI & DW SOLUTIONS",
    icon: <RiFileExcel2Fill size={45} />,
    desc: "Designed hundreds of facts and dimensions including conformed dimensions and built ETL processes to load them from numerous data sources (files - csv, xml etc and Oracle, MySQL,for different source applications).",
  },
];

// Our team data
export const employees = [
  {
    id: "uv001",
    name: "Chanchalkumar Ghevade",
    photo: CG,
    designation: "Founder-Director",
  },
  {
    id: "uv002",
    name: "Rasheed Kannan",
    photo: RK,
    designation: "Director, Malaysia",
  },
  { id: "uv003", name: "Monika Korde", photo: MK, designation: "HR Partner" },
  {
    id: "uv004",
    name: "Sara Shaikh",
    photo: SS,
    designation: "Junior Software Engineer",
  },
  {
    id: "uv005",
    name: "Supriya Pawar",
    photo: SUP,
    designation: "Senior Software Engineer",
  },
  {
    id: "uv006",
    name: "Ranjit yadav",
    photo: RY,
    designation: "Senior Business Analyst",
  },
  {
    id: "uv007",
    name: "Jitendra Badgujar",
    photo: JB,
    designation: "Senior Accountant",
  },
  {
    id: "uv008",
    name: "Sagar Tilekar",
    photo: ST,
    designation: "Junior Software Engineer",
  },
  {
    id: "uv009",
    name: "Shubham Parab",
    photo: SHP,
    designation: "Junior Software Engineer",
  },
  {
    id: "uv010",
    name: "Sachin Yadav",
    photo: SY,
    designation: "Junior Software Engineer",
  },
  {
    id: "uv011",
    name: "Gayatri Tajane",
    photo: GT,
    designation: "Junior Software Engineer",
  },
  {
    id: "uv012",
    name: "Prapti Gomekar",
    photo: PG,
    designation: "Junior Software Engineer",
  },
  {
    id: "uv013",
    name: "Vaibhav Rajgure",
    photo: VR,
    designation: "Software Engineer",
  },
];

// Vision-mission data
export const visionMissionData = [
  {
    id: "vm001",
    img: OurMission,
    title: "Our Mission",
    desc: "We aim to emerge as the preferred technology partner for our clients, by powering them through innovative focused solutions for their real life problems.",
  },
  {
    id: "vm002",
    img: OurVision,
    title: "Our Vision",
    desc: " Know how to pursue pleasure rationally encounter consequences that are extremely painful nor again is there anyone or  pursues.",
  },
  {
    id: "vm003",
    img: OurValues,
    title: "Our Values",
    desc: "Know how to pursue pleasure rationally encounter consequences that are extremely painful nor again is there anyone or pursues.",
  },
];
