import { useEffect, useState, useCallback } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import ScrollButton from "./8.Scroll-To-Top/ScrollButton";
import ScrollToTop from "./8.Scroll-To-Top/ScrollToTop";
import ApplyForm from "./10.ApplyJob/ApplyForm";
import Home from "./2.HomePage/Home";
import AboutUs from "./3.About/AboutUs";
import Services from "./4.Services/Services";
import Technologies from "./5.Technologies/Technologies";
import ContactUs from "./7.Contact/ContactUs";
import PageNotFoundError from "./12.PageNotFound/PageNotFoundError";
import CareersSimple from "./6.Careers/CareersSimple";
// import Careers from "./6.Careers/Careers";
import Navbar from "./1.Navbar/Navbar";
import Footer from "./9.Footer/Footer";

function App() {
  const location = useLocation();
  const [scrollTop, setScrollTop] = useState(0);
  const [oldPath, setOldPath] = useState(undefined);

  const onScroll = () => {
    const winScroll = document.documentElement.scrollTop;
    const height =
      document.documentElement.scrollHeight -
      document.documentElement.clientHeight;
    const scrolled = (winScroll / height) * 100;
    setScrollTop(scrolled);
  };

  function reveal() {
    var reveals = document.querySelectorAll(".reveal");
    for (var i = 0; i < reveals.length; i++) {
      var windowHeight = window.innerHeight;
      var elementTop = reveals[i].getBoundingClientRect().top;
      var elementVisible = (windowHeight * 2) / 10;
      if (elementTop < windowHeight - elementVisible) {
        reveals[i].classList.add("active");
      }
    }
  }

  const removeRevealActive = useCallback(() => {
    var reveals = document.querySelectorAll(".reveal");
    for (var i = 0; i < reveals.length; i++) {
      if (reveals[i].classList.contains("active")) {
        reveals[i].classList.remove("active");
      }
    }
    setOldPath(location.pathname);
  }, [location]);

  useEffect(() => {
    window.addEventListener("scroll", onScroll);
    window.addEventListener("scroll", reveal);
    if (location.pathname !== oldPath) {
      setTimeout(() => removeRevealActive(), 601);
    }
    return () => {
      window.removeEventListener("scroll", onScroll);
      window.removeEventListener("scroll", reveal);
    };
  }, [location, removeRevealActive, oldPath]);

  return (
    <>
      <Navbar />

      <ToastContainer autoClose={2000} className="toast-container" />
      <div
        className={`progress fixed-top  ${scrollTop === 0 ? "d-none" : ""}`}
        style={{ height: "2px" }}
      >
        <div
          className="progress-bar"
          role="progressbar"
          style={{ width: `${scrollTop}%`, background: "#01368a" }}
          aria-valuemin="0"
          aria-valuemax="100"
        ></div>
      </div>
      <ScrollToTop>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<AboutUs />} />
          <Route path="/services" element={<Services />} />
          {/* <Route path="/careers" element={<Careers />} /> */}
          <Route path="/careers" element={<CareersSimple />} />
          <Route path="/contact" element={<ContactUs />} />
          <Route path="/technologies" element={<Technologies />} />
          <Route path="/applyform" element={<ApplyForm />} />
          <Route path="*" element={<PageNotFoundError />}></Route>
        </Routes>
      </ScrollToTop>
      <ScrollButton />

      <Footer />
    </>
  );
}

export default App;
