import React, { useEffect, useRef } from "react";
import ContactForm from "./ContactForm";
import { BsEnvelope, BsTelephone, BsChevronDown } from "react-icons/bs";
import { HiOutlineLocationMarker } from "react-icons/hi";

const ContactUs = () => {
  const office1Ref = useRef();
  const office2Ref = useRef();
  const expandArrow1Ref = useRef();
  const expandArrow2Ref = useRef();

  const toggleExpandArrow = () => {
    if (office1Ref.current.getAttribute("aria-expanded") === "true") {
      expandArrow1Ref.current.classList.add("rotate-180");
    } else {
      expandArrow1Ref.current.classList.remove("rotate-180");
    }
    if (office2Ref.current.getAttribute("aria-expanded") === "true") {
      expandArrow2Ref.current.classList.add("rotate-180");
    } else {
      expandArrow2Ref.current.classList.remove("rotate-180");
    }
  };

  useEffect(() => {
    document.getElementById("main-title").textContent = "uvXcel - Contact Us";
    toggleExpandArrow();
  });

  return (
    <>
      <section className="contact padding-and-height-for-main-container">
        <div className="container">
          <div className="text-center ">
            <h5 className="text-uppercase primary-color-heading active fade-bottom">
              Let's Start a Conversation
            </h5>
            <h1 className="fw-bold primary-color-heading active fade-up">
              Contact Us
            </h1>
          </div>
        </div>
        <div className="container-fluid">
          <div className="row mt-md-5 mt-4 justify-content-evenly ">
            <div className="col-lg-5 col-md-5 mt-1 active fade-left">
              {/* India Office */}
              <div className="col-12">
                <a
                  ref={office1Ref}
                  onClick={toggleExpandArrow}
                  className="card office-address-link-card-1  py-2 px-3"
                  data-bs-toggle="collapse"
                  href="#collapseExample"
                  role="button"
                  aria-expanded="true"
                  aria-controls="collapseExample"
                >
                  <div className="row justify-content-between align-items-center custom-office-row">
                    <div className="office-title">
                      <h5>India Office</h5>
                    </div>

                    <div ref={expandArrow1Ref} className="expand-arrow-1">
                      <BsChevronDown size={24} />
                    </div>
                  </div>
                </a>

                <div className="collapse show" id="collapseExample">
                  <div className="card-body pt-4 px-2">
                    <h6 className="primary-color-heading">Pune:</h6>
                    <div className="row">
                      <div className="col-12 mt-1">
                        <div className="row address-row">
                          <div className="col-1">
                            <HiOutlineLocationMarker />
                          </div>
                          <div className="col-11">
                            <p>
                              Unit No. 223, Goodwill Square Mall, Dhanori, Pune
                              - 411015, Maharashtra, India.
                            </p>
                            <p className="bg-light mt-3">
                              <iframe
                                title="office-address"
                                className="gmap_iframe"
                                src="https://maps.google.com/maps?hl=en&amp;q=uvxcel&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
                              ></iframe>
                            </p>
                          </div>
                        </div>
                        <hr />
                        <div className="row address-row">
                          <div className="col-1">
                            <BsTelephone />
                          </div>
                          <div className="col-11">
                            For Enquiry: +91-20-67026259 | marketing@uvxcel.com
                          </div>
                        </div>
                        <hr />
                        <div className="row address-row">
                          <div className="col-1">
                            <BsEnvelope />
                          </div>
                          <div className="col-11 mb-4">
                            For career opportunities: hr@uvxcel.com
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* Malaysia Office */}
              <div className="col-12 mt-3">
                <a
                  onClick={toggleExpandArrow}
                  ref={office2Ref}
                  className="card office-address-link-card-2  py-2 px-3"
                  data-bs-toggle="collapse"
                  href="#collapseExample2"
                  role="button"
                  aria-expanded="false"
                  aria-controls="collapseExample"
                >
                  <div className="row justify-content-between align-items-center custom-office-row">
                    <div className="office-title">
                      <h5>Malaysia Office</h5>
                    </div>
                    <div ref={expandArrow2Ref} className="expand-arrow-2">
                      <BsChevronDown size={24} />
                    </div>
                  </div>
                </a>

                <div className="collapse" id="collapseExample2">
                  <div className="card-body pt-4">
                    <h6 className="primary-color-heading">Kuala Lumpur:</h6>
                    <div className="row">
                      <div className="col-12 mt-1">
                        <div className="row address-row">
                          <div className="col-1">
                            <HiOutlineLocationMarker />
                          </div>
                          <div className="col-11">
                            <p>
                              Level 33, Ilham Tower, No.8, Jalan Binjai, 50450
                              Kuala Lumpur, Malaysia.
                            </p>
                            <p className="bg-light mt-3">
                              <iframe
                                title="office-address"
                                className="gmap_iframe"
                                src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=Level%2033,Ilham%20Tower,No.8,Jalan%20Binjai,50450%20Kuala%20Lumpur,Malaysia.+(My%20Business%20Name)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
                              >
                                <a href="https://www.maps.ie/distance-area-calculator.html">
                                  measure acres/hectares on map
                                </a>
                              </iframe>
                            </p>
                          </div>
                        </div>
                        <hr />
                        <div className="row address-row">
                          <div className="col-1">
                            <BsTelephone />
                          </div>
                          <div className="col-11">
                            For Enquiry: +60-12-200-0340 | marketing@uvxcel.com
                          </div>
                        </div>
                        <hr />
                        <div className="row address-row">
                          <div className="col-1">
                            <BsEnvelope />
                          </div>
                          <div className="col-11 mb-4">
                            For career opportunities: hr@uvxcel.com
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-5 col-md-6 my-md-0 my-5 column2 active fade-right">
              <ContactForm />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ContactUs;
