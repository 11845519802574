import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import axios from "axios";
import { MdVerified } from "react-icons/md";
import { RxReload } from "react-icons/rx";
import PhoneInput from "react-phone-input-2";
import { parsePhoneNumberFromString } from "libphonenumber-js";

import {
  LoadCanvasTemplate,
  loadCaptchaEnginge,
  validateCaptcha,
} from "react-simple-captcha";

const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    topic: "Enquiry",
    helptext: "",
    countrycode: "+91",
    mobile: "",
  });

  const [captchaVerified, setCaptchaVerified] = useState(false);
  const [captchaErr, setCaptchaErr] = useState(false);
  const captchaRef = useRef();

  const { name, email, helptext, mobile } = formData;

  const [validationDetails, setValidationDetails] = useState({
    nameErr: false,
    emailErr: false,
    helpErr: false,
    mobileErr: false,
  });

  const [sendMsgBtnDetails, setSendMsgBtnDetails] = useState({
    loading: false,
    submitBtnTxt: "Send Message",
  });

  const { nameErr, emailErr, helpErr, mobileErr } = validationDetails;
  const { loading, submitBtnTxt } = sendMsgBtnDetails;

  const onInputBlur = (e) => {
    const { name, value } = e.target;
    if (name === "name") {
      if (value) {
        if (value.length < 2) {
          e.target.style.borderColor = "red";
          setValidationDetails({ ...validationDetails, nameErr: true });
        } else {
          setValidationDetails({ ...validationDetails, nameErr: false });
          e.target.style.borderColor = "";
        }
      }
    } else if (name === "email") {
      var mailformat = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
      if (value) {
        if (mailformat.test(value)) {
          setValidationDetails({ ...validationDetails, emailErr: false });
          e.target.style.borderColor = "";
        } else {
          setValidationDetails({ ...validationDetails, emailErr: true });
          e.target.style.borderColor = "red";
        }
      }
    } else if (name === "helptext") {
      if (value) {
        if (value.length < 5) {
          e.target.style.borderColor = "red";
          setValidationDetails({ ...validationDetails, helpErr: true });
        } else {
          setValidationDetails({ ...validationDetails, helpErr: false });
          e.target.style.borderColor = "";
        }
      }
    }
  };

  const onInputChange = (e) => {
      const { name, value } = e.target;
      e.target.style.borderColor = "";
      if (name === "name") {
        setValidationDetails({ ...validationDetails, nameErr: false });
        setFormData({ ...formData, [name]: value });
      } else if (name === "email") {
        setValidationDetails({ ...validationDetails, emailErr: false });
        setFormData({ ...formData, [name]: value.toLowerCase() });
      } else if (name === "helptext") {
        setValidationDetails({ ...validationDetails, helpErr: false });
        setFormData({ ...formData, [name]: value });
      } else if (name === "topic") {
        e.target.style.borderColor = "";
        if (value === "Issue") {
          document.getElementById("help").placeholder =
            "Please describe your issue ";
        } else {
          document.getElementById("help").placeholder = "How Can We help You ?";
        }
        setFormData({ ...formData, [e.target.name]: e.target.value });
      }
      //  else if (name === "mobile") {
      //   console.log(value);
      //   const mobileRegex = /^\d{10}$/;

      //   console.log(mobileRegex.test(value));
      //   // if (value.length <= 10) {
      //   //   setMobileNumber(value);
      //   // }

      //   setFormData({
      //     ...formData,
      //     [name]: countrycode + value,
      //   });
      // } 
  };

  const onMobileNumberInputChange = (phone, country, e, formattedValue) => {
    let parsedPhoneNumber = parsePhoneNumberFromString(e.target.value);
    let isValid = parsedPhoneNumber ? parsedPhoneNumber.isValid() : false;
    let finalValue = parsedPhoneNumber ? parsedPhoneNumber.number : null;

    console.log(parsedPhoneNumber, isValid, finalValue);
    if (!isValid) {
      setValidationDetails({
        ...validationDetails,
        mobileValidationMessage: "Invalid Mobile Number Entered.",
        mobileErr: true
      });
    } else {
      setValidationDetails({
        ...validationDetails,
        mobileValidationMessage: "",
        mobileErr: false
      });
      setFormData({
            ...formData,
            mobile: finalValue,
          });
    }

  };

  const onSubmit = async (e) => {
    setFormData({ ...formData });
    e.preventDefault();
    if (
      nameErr === true ||
      emailErr === true ||
      mobileErr === true ||
      helpErr === true || !captchaVerified
    ) {
      if (!captchaVerified) {
        setCaptchaErr(true);
      }else{
        toast.error("Please enter correct details in the form");
      }

    } else {
      console.log(formData);
      setSendMsgBtnDetails({
        ...sendMsgBtnDetails,
        loading: true,
        submitBtnTxt: "Sending...",
      });
      await axios.post(
        "https://uvxcel-server.azurewebsites.net/save_data",
        formData
      );
      setTimeout(() => {
        setSendMsgBtnDetails({
          ...sendMsgBtnDetails,
          loading: false,
          submitBtnTxt: "Send Message",
        });
        toast.success("MESSAGE SENT SUCCESSFULLY");
        setCaptchaVerified(false);
        e.target.reset();
      }, 1000);
    }
  };

  const onCaptchaSubmit = (e) => {
    e.preventDefault();
    let user_captcha = captchaRef.current.value;
    if (user_captcha) {
      if (validateCaptcha(user_captcha) === true) {
        setCaptchaVerified(true);
        setCaptchaErr(false);
        loadCaptchaEnginge(6);
        captchaRef.current.value = "";
      } else {
        setCaptchaVerified(false);
        setCaptchaErr(true);
        captchaRef.current.value = "";
      }
    }
  };

  const loadCaptchaOnRefresh = () => {
    loadCaptchaEnginge(6);
    document.getElementById("reload_href").classList.add("d-none");
    const captchaWrapper =
      document.getElementById("captcha-wrapper").firstChild;
    captchaWrapper.classList.add("flexAndCenter");
  };

  useEffect(() => {
    loadCaptchaOnRefresh();
  }, []);

  return (
    <form
      method="POST"
      onSubmit={(e) => onSubmit(e)}
      className="card contact-form p-4 shadow-sm"
    >
      <div className="row justify-content-center">
        <div className="mb-4 col-6">
          <div className="form-group">
            <input
              onBlur={onInputBlur}
              onChange={onInputChange}
              type="text"
              name="name"
              className="form-control p-2"
              id="name"
              placeholder="Full Name"
              required
            />
          </div>
          <span className={`text-danger ${nameErr ? "" : "d-none"}`}>
            Min 2 characters required.
          </span>
        </div>
        <div className="mb-4 col-6">
          <div className="form-group">
            <input
              onBlur={onInputBlur}
              onChange={onInputChange}
              type="text"
              name="email"
              className="form-control p-2 text-lowercase"
              id="email"
              placeholder="Email"
              required
            />
          </div>
          <span className={`text-danger ${emailErr ? "" : "d-none"}`}>
            Email is not valid.
          </span>
        </div>
        <div className="col-lg-6 col-12">
          <div className="form-group mb-4">
            <div className="input-group">              
              <PhoneInput
                name="mobile"
                // country={"in"}
                value={''}
                placeholder={'Mobile number'}
                onChange={onMobileNumberInputChange}
              />
              <span className={`text-danger ${mobileErr ? "" : "d-none"}`}>
                Please enter valid mobile number
              </span>
            </div>
          </div>
        </div>
        <div className="col-lg-6 col-12">
          <div className="form-group mb-4">
            <select
              id="topic"
              name="topic"
              className="form-select topic form-control p-2"
              aria-label="Default select example"
              required
              onChange={onInputChange}
            >
              <option value="Enquiry">Enquiry</option>
              <option value="Issue">Report an issue</option>
              <option value="Other">Other</option>
            </select>
          </div>
        </div>
        <div className="col-12">
          <div className="form-group mb-4">
            <textarea
              maxLength={150}
              onBlur={onInputBlur}
              onChange={onInputChange}
              cols="30"
              name="helptext"
              rows="5"
              type="text"
              className="form-control p-2 text"
              id="help"
              placeholder="How Can We help You ?"
              style={{ resize: "none" }}
              required
            />
            <span className={`text-danger ${helpErr ? "" : "d-none"}`}>
              Please enter your message. ( min. 5 characters )
            </span>
          </div>
        </div>
        <div className={`col-12 ${captchaVerified ? "d-none" : ""}`}>
          <div className="container">
            <div className="row">
              <div
                id="captcha-wrapper"
                className="ps-0 col-xl-5 col-md-9 col-8"
              >
                <LoadCanvasTemplate />
              </div>
              <div className="col-xl-1 col-md-3 col-4 reload-captcha-icon">
                <RxReload
                  onClick={() => {
                    loadCaptchaEnginge(6);
                  }}
                  cursor="pointer"
                  style={{ transition: ".3s ease-in-out" }}
                />
              </div>
              <div className="captcha-inputBox col-xl-4 col-md-9 col-8 mt-3 mt-xl-0">
                <input
                  placeholder="Enter Captcha"
                  type="text"
                  className={`form-control mt-2 mt-md-0 ${captchaErr ? "border-danger" : ""
                    }`}
                  ref={captchaRef}
                />
                <span className={`text-danger ${captchaErr ? "" : "d-none"}`}>
                  Invalid Captcha
                </span>
              </div>

              <div className="col-xl-2 col-md-3 col-4 mt-4 mt-md-3 mt-xl-0 p-0">
                <button
                  type="button"
                  className="btn w-100 primary-btn"
                  onClick={onCaptchaSubmit}
                >
                  Verify
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className={`col-12 ${captchaVerified ? "" : "d-none"}`}>
          <button className="btn btn-outline-success fw-bold disabled w-100">
            Verified <MdVerified color="green" />
          </button>
        </div>
        <span className="form-group col-12 mt-4">
          <button
            type="submit"
            id="submit-btn"
            className={`btn primary-btn py-2 w-100 ${loading ? "disabled" : ""
              }`}
            disabled={
              name && email && helptext.length >= 5 && mobile && captchaVerified
                ? false
                : true
            }
          >
            <span
              className={`${loading ? "" : "d-none"
                }spinner-grow spinner-grow-sm me-2`}
              role="status"
              aria-hidden="true"
            ></span>
            {submitBtnTxt}
          </button>
        </span>
      </div>
    </form>
  );
};

export default ContactForm;
